.underline-from-center::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: currentColor;
    transition: transform 0.3s ease-in-out;
    transform: scaleX(0);
    transform-origin: center;
  }

  .underline-from-center:hover::after {
    transform: scaleX(1);
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fade-in {
    animation: fadeIn 1s ease-out forwards;
  }
  

  @keyframes bounceInUp {
    0%, 60%, 75%, 90%, 100% {
      transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    0% {
      opacity: 1;
      transform: translateY(100vh);
    }
    60% {
      opacity: 1;
      transform: translateY(-15px); /* Zmniejszona wartość odbicia */
    }
    75% {
      transform: translateY(8px); /* Zmniejszona wartość odbicia */
    }
    90% {
      transform: translateY(-4px); /* Zmniejszona wartość odbicia */
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes makeVisible {
    0% { visibility: visible; }
  }

  /* Animacja bounce-in-up z zarządzaniem widocznością */
  .bounce-in-up {
    animation: bounceInUp 2s ease, makeVisible 0s linear 0s forwards;
    animation-fill-mode: forwards; /* Zachowaj stan po zakończeniu animacji */
  }

    
