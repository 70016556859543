

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titan+One&display=swap');

body {
  margin: 0;
  font-family: 'Dosis', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer utilities {
  @keyframes custom-bounce {
    0%, 100% {
      transform: translateY(-5%); /* Zmniejszona amplituda */
      animation-timing-function: cubic-bezier(0.8,0,1,1);
    }
    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0,0,0.2,1);
    }
  }
  .animate-custom-bounce {
    animation: custom-bounce 1s infinite;
  }
}